<template>
  <div class="main-content">
    <div class="panel-wrapper">
      <div>
        <h1 class="headline">
          Notify by
        </h1>
        <h2 class="subline">
          Please select upon which action you would like to be notified via e-mail
        </h2>
      </div>
    </div>

    <transition name="fade">
      <div class="inner-container" v-show="initialized">
        <div class="form-group text-left" v-for="(item, n) of checkboxList" :key="n">
          <checkbox :label="item.label" v-model="checkboxList[n].value" @input="updateNotifications" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Checkbox from "@/libs/Checkbox";
import debounce from 'lodash/debounce'

export default {
  name: 'Notification',
  components: {
    Checkbox
  },
  data () {
    return {
      initialized: false,
      checkboxList: null
    }
  },
  computed: {
    formData () {
      const data = {}

      for (const item of this.checkboxList) {
        data[item.name] = item.value ? 1 : 0
      }

      return data
    }
  },
  created () {
    return this.$sdk.settings.updateNotifications().then(response => {
      this.checkboxList = response.data
      this.initialized = true
    })
  },
  methods: {
    updateNotifications () {
      return this.$sdk.settings.updateNotifications(this.formData).then(({ data }) => {
        this.checkboxList = data
        this.showNotices()
      }).catch(() => {
        this.$notice.error('Something was wrong. Please reload page and try again')
      })
    },

    showNotices: debounce(function () {
      this.$notice.success('Changes were saved successfully.')
    }, 300)
  }
}
</script>

<style lang="less" scoped>
  .headline-helper {
    margin-top: 30px;
    color: rgb(117, 117, 118);
    line-height: 1.4em;
  }

  .inner-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
</style>
